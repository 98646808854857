import React from "react"
import { Link, graphql } from "gatsby"

  import {
    MdHelp,
    MdArrowBack,
    MdSupervisorAccount,
    MdDvr,
    MdCheck,
    MdArrowForward,
    MdList
  } from 'react-icons/md';


import SEO from "../components/seo"
import Layout from "../components/layout"

const SupportTec = ({data: {image1,network }}) => {


    let imgProd = network.childImageSharp.fluid.src;

    return (
        <Layout changeHeader={2}>
            <SEO title={`Suporte de redes Informática`} />

            <section className="hero-wrap hero-wrap-2 mb-2" style={{backgroundImage: `url(${image1.childImageSharp.fluid.src})`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>

                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                            <Link to ="/#services-section" className="icon ml-5 btn btn-primary" title="Regresar">
                                <MdArrowBack
                                    color="#fff"
                                    size="2.3em"
                                /> 
                            </Link>
                        </div>

                        <div className="col-md-11 text-center">
                            <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Soporte de redes Informática </h1>
                            <p className="breadcrumbs"><span className="mr-2">
                                <Link  className="" to="/#services-section">Servicios</Link> > </span> <span>Soporte de redes Informática</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-justify" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="sidebar-box" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <h3 className="text-center">Soporte de redes</h3>

                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Las redes constituyen una parte fundamental de toda organización, pero el mantenimiento y operación de ellas puede ser costosa y complicada, por eso en Metalinks le ofrecemos el servicio especializado de instalación, operación y mantenimiento de redes.</p>

                                <h3 className="text-center">¿Por qué es importante?</h3>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Como podemos ver a día de hoy, para cualquier empresa de más de dos usuarios, el uso de una red informática es prácticamente imprescindible. Mediante esta infraestructura abarataremos costes tanto en hardware como en software. También optimizaremos el trabajo común de nuestros empleados y mantendremos nuestros datos y los de nuestros clientes a buen recaudo.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 p-md-7 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                            <img src={imgProd} alt="img project" className="img-fluid rounded mb-1" />
                        </div>

                        <div className="col-md-12 col-12 p-md-4 mt-3" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease" >
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section ftco-no-pb" id="contact-section" style={{padding: '2em 0'}}>
              <div className="container-fluid mb-4">
                <div className="row justify-content-center mb-1 pb-3">
                  <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                    <h3 className="mb-4">Pasos</h3>
                  </div>
                </div>
                <div className="row d-flex contact-info mb-0 justify-content-center">
                  
                  <div className="col-md-4 col-lg-2 d-flex " style={{}}> 
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px', background: '#388e3c'}} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdSupervisorAccount color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Entrevista</h6>
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="2em"
                    /> 
                  </div>

                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#f9a825'}} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdDvr color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Presentación de propuesta</h6>
              
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-none d-lg-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="2em"
                    /> 
                  </div> 
                  
                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#ef6c00'}} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdList color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Dianóstico o Requerimientos</h6>
                    </div>
                  </div>

                  <div className="pt-5 d-none d-sm-none d-md-block" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                    <MdArrowForward
                        color="#33a4db"
                        size="2em"
                    /> 
                  </div>

                  <div className="col-md-4 col-lg-2 d-flex ">
                    <div className="align-self-stretch box p-4 text-center" style={{borderRadius: '15px',background: '#039be5' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                      <div className="icon d-flex align-items-center justify-content-center" style={{marginBottom: '3px',width: '50px',height: '50px'}}>
                        <MdCheck color="#ffffff" size="2em"/>
                      </div>
                      <h6 className="">Aplicación de solución</h6>
                    </div>
                  </div>

                </div>
                
              </div>
            </section>

            <section className="ftco-section ftco-no-pb ftco-no-pt ftco-services bg-light mb-5" id="services-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-2 py-5 nav-link-wrap" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <a className="nav-link px-0 active title_tabs" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="true" title="Diseño e Instalación de Red.">
                        Diseño &nbsp;
                        <MdHelp/>
                      </a>
        
                      <a className="nav-link px-0 title_tabs" id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false" title="Configuración de Red Cableadas e Inalámbricas">
                        Config. de Red
                        &nbsp;
                        <MdHelp/>
                        </a>
        
                      <a className="nav-link px-0 title_tabs" id="v-pills-3-tab" data-toggle="pill" href="#v-pills-3" role="tab" aria-controls="v-pills-3" aria-selected="false" title="Voz, Datos y Videos.">
                        Voz, Datos
                        &nbsp;
                        <MdHelp/>
                        </a>
        
                      <a className="nav-link px-0 title_tabs" id="v-pills-4-tab" data-toggle="pill" href="#v-pills-4" role="tab" aria-controls="v-pills-4" aria-selected="false" title="Voz sobre IP.">
                      Voz sobre IP
                        &nbsp;
                        <MdHelp/>
                        </a>

                        <a className="nav-link px-0 title_tabs" id="v-pills-5-tab" data-toggle="pill" href="#v-pills-5" role="tab" aria-controls="v-pills-4" aria-selected="false" title="Almacenamiento y Respaldos Locales.">
                        Almac. Locales
                        &nbsp;
                        <MdHelp/>
                        </a>

                        <a className="nav-link px-0 title_tabs" id="v-pills-6-tab" data-toggle="pill" href="#v-pills-6" role="tab" aria-controls="v-pills-4" aria-selected="false" title="Almacenamiento y Respaldos en Nubes.">
                        Almac. En Nubes
                        &nbsp;
                        <MdHelp/>
                        </a>
        
                    </div>
                  </div>
                  <div className="col-md-10 p-0 p-md-0 d-flex align-items-center" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                    
                    <div className="tab-content pl-md-1" id="v-pills-tabContent">
        
                        <div className="tab-pane fade show active py-5" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-1-tab">
                            <div className="p-5">
                                <h2 className="mb-4">Diseño e Instalación de Red.</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Es proporcionar una plataforma sobre la cual se construye una estrategia global del sistema de información, que sirven de estructura para el desarrollo de múltiples sistemas de voz, datos, vídeo, multimedia, independientemente del fabricante.</p>
                            </div>
                        </div>
            
                        <div className="tab-pane fade py-5" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-2-tab">
                            <div className="p-5">
                                <h2 className="mb-4">Configuración de Red Cableadas e Inalámbricas</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Es el proceso de organizar y mantener información sobre todos los componentes de una red informática. Cuando una red necesita reparación, modificación, expansión o actualización, el administrador se refiere a la base de datos de gestión de configuración de red para determinar el mejor curso de acción. Esta base de datos contiene las ubicaciones y la dirección IP o la dirección de red de todos los dispositivos de hardware, así como información sobre la configuración predeterminada, los programas, las versiones y las actualizaciones instaladas en las computadoras de la red.</p>
                            </div>
                        </div>
            
                        <div className="tab-pane fade py-5" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-3-tab">
                            <div className="p-5">
                                <h2 className="mb-4">Voz, Datos y Videos</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Una red de voz y datos, sistema de cableado estructurado, unifica en una misma infraestructura de telecomunicaciones los servicios de voz, datos y video con un sistema de gestión centralizado, aportando importantes beneficios para las empresas.</p>
                            </div>
                        </div>
            
                        <div className="tab-pane fade py-5" id="v-pills-4" role="tabpanel" aria-labelledby="v-pills-4-tab">
                            <div className="p-5">
                                <h2 className="mb-4">Voz sobre IP</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Voz sobre IP se refiere a la transmisión del tráfico de voz sobre redes basadas en Internet en lugar de las redes telefónicas tradicionales PSTN (red telefónica pública conmutada). El protocolo de internet (IP) fue diseñado originalmente para redes de transición de datos, y debido a su gran éxito fue adaptado a las redes de voz mediante la paquetización de la información y transmisión de la misma como paquetes de datos IP. VoIP está disponible en muchos teléfonos inteligentes, computadoras personales y en los dispositivos de acceso a Internet, tales como tabletas.</p>
                            </div>
                        </div>

                        <div className="tab-pane fade py-5" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-5-tab">
                            <div className="p-5">
                                <h2 className="mb-4"> Almacenamiento y Respaldos Locales</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;El almacenamiento local es lo que conocemos desde que se inventó el primer ordenador. Es decir, guardar tus fotos, documentos o archivos en un disco duro, memoria SD, cinta magnética o cualquier otro medio de almacenamiento que tienes físicamente en tu casa/oficina o dentro de tu móvil u ordenador.</p>
                            </div>
                        </div>

                        <div className="tab-pane fade py-5" id="v-pills-6" role="tabpanel" aria-labelledby="v-pills-6-tab">
                            <div className="p-5">
                                <h2 className="mb-4">Almacenamiento y Respaldos en Nubes</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Almacenamiento en la nube (o cloud storage, en inglés) es un modelo de servicio en el cual los datos de un sistema de cómputo se almacenan, se administran, y se respaldan de forma remota, típicamente en servidores que están en la nube y que son administrados por un proveedor del servicio. Estos datos se ponen a disposición de los usuarios a través de una red, como lo es Internet.</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

        </Layout>
    )
}

export default SupportTec

export const pageQuery = graphql`
  query FacBySupportNet {
    image1: file(relativePath: { eq: "network_banner.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    network: file(relativePath: { eq: "networks.jpg" }) {
      childImageSharp {
          fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
          }
      }
    }
   

  }
`